/* eslint-disable */
import Header from '../../Header.vue'
import Footer from '../../Footer.vue'
import UserLeft from '../PersonUserLeft.vue'
import CheckInService from '../../../api/CheckInService.js'

export default{
	name:'EditPerEmail',
	data(){
		return{
			userInfo:'',
			userEmail:'', //邮箱
			userCode:'', //验证码
			isGetCode:false,
			codeTime:0,
			sendCode:''
		}
	},
	methods:{
		GetUserInfo(){ //获取用户信息
			var that = this
			if(that.$userType == 0){
				CheckInService.GetUserPerInfo(that.$UserID).then(res=>{
					if(res.Tag == 1){
						that.userInfo = res.Result
						that.userEmail = that.userInfo.Email
					}
				})
			}else{
				CheckInService.GetUserOrgInfo(that.$UserID).then(res=>{
					if(res.Tag == 1){
						that.userInfo = res.Result
						that.userEmail = that.userInfo.Email
					}
				})
			}
			
		},
		GetCode(){
			var that = this
			if(that.userEmail.trim() == ''){
				$('#userEmail input').addClass('error')
				$('#userEmail .errorts').text('请输入邮箱')
				return
			}else if(!(/^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/.test(that.userEmail.trim()))){
				$('#userEmail input').addClass('error')
				$('#userEmail .errorts').text('请输入正确格式邮箱')
				return
			}
			$('#userEmail input').removeClass('error')
			$('#userEmail .errorts').text('')
			CheckInService.SendValidCode(that.$UserID,that.userEmail.trim(),4,1).then(res=>{
				if(res.Tag == 1){
					that.sendCode = res.Description 
				}else{
					that.$alert('发送失败，请稍后重试', '提示', {
						confirmButtonText: '确定'
					});
				}
			})
			that.codeTime = 60
			that.isGetCode = true
			var setInterv = setInterval(function() {
				that.codeTime--;
				if (that.codeTime < 0) {
					that.codeTime = 60
					clearInterval(setInterv)
					that.isGetCode = false
				}
			}, 1000)
		},
		SaveEdit(){
			var that = this
			if(that.userInfo.Email == ''){
				if(that.userEmail.trim() == ''){
					$('#userEmail input').addClass('error')
					$('#userEmail .errorts').text('请输入邮箱')
					return
				}else if(!(/^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/.test(that.userEmail.trim()))){
					$('#userEmail input').addClass('error')
					$('#userEmail .errorts').text('请输入正确格式邮箱')
					return
				}else if(that.userCode.trim() == ''){
					$('#userCode input').addClass('error')
					$('#userCode .errorts').text('请输入验证码')
					$('#userEmail input').removeClass('error')
					$('#userEmail .errorts').text('')
					return
				}else if(that.userCode.trim() != that.sendCode){
					$('#userCode input').addClass('error')
					$('#userCode .errorts').text('请输入正确验证码')
					$('#userEmail input').removeClass('error')
					$('#userEmail .errorts').text('')
					return
				}
				$('#userCode input').removeClass('error')
				$('#userCode .errorts').text('')
				$('#userEmail input').removeClass('error')
				$('#userEmail .errorts').text('')
				CheckInService.ValidCode(that.$UserID,that.userEmail.trim(),4,1,that.userCode.trim()).then(res=>{
					console.log(res)
					if(res.Tag == 1){
						CheckInService.UpdateAccount(that.$UserID,that.userEmail.trim(),1).then(msg=>{
							if(msg.Tag == 1){
								that.$router.go(-1)
							}else{
								that.$alert(msg.Message, '提示', {
									confirmButtonText: '确定'
								});
							}
						})
					}else{
						that.$alert(res.Message, '提示', {
							confirmButtonText: '确定'
						});
					}
				})
			}else{
				if(that.userCode.trim() == ''){
					$('#userCode input').addClass('error')
					$('#userCode .errorts').text('请输入验证码')
					return
				}else if(that.userCode.trim() != that.sendCode){
					$('#userCode input').addClass('error')
					$('#userCode .errorts').text('请输入正确验证码')
					return
				}
				$('#userCode input').removeClass('error')
				$('#userCode .errorts').text('')
				CheckInService.ValidCode(that.$UserID,that.userInfo.Email,4,1,that.userCode.trim()).then(res=>{
					if(res.Tag == 1){
						that.$router.push({
							path:'/EditSecPerEmail'
						})
					}else{
						that.$alert(res.Message, '提示', {
							confirmButtonText: '确定'
						});
					}
				})
			}
			
		}
	},
	created(){
		this.GetUserInfo()
	},
	components:{
		'Header':Header,
		'Footer':Footer,
		'UserLeft':UserLeft,
	}
}